/* .onoffswitch {
    position: relative;
    width: 155px;
    top: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #999999;
    border-radius: 20px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "Followed!";
    padding-left: 10px;
    background-color: green;
    color: #FFFFFF;
}

.onoffswitch-inner:after {
    content: "Not followed";
    padding-right: 10px;
    background-color: red;
    color: #FFFFFF;
    text-align: right;
}

.onoffswitch-switch {
    display: block;
    width: 46px;
    margin: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 101px;
    border: 2px solid #999999;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0px;
} */

.toggle-button {
    height: 30px;
    width: fit-content;
    border-radius: 5px;
    background-color: #ddd;
    border: none;
    cursor: pointer;
    font-size: 13px;
    transition: background-color 0.3s ease;
}

.toggle-button.on {
    background-color: #4CAF50;
    color: white;
}

.toggle-button.off {
    background-color: #f44336;
    color: white;
}