body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
#popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

#vio-det {
  position: relative;
  top: 49.9%;
  left: 50%;
  color: rgb(255, 255, 255);
  transform: translateX(0%);
  color: rgb(255, 255, 255);
}

.o-player {
  background-color: #ffffff;
  border: 1px solid #ced5dd;
  border-radius: 5px;
  width: 200px;
  height: 500px;
  overflow: hidden;
} */