@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

.error {
    border: 1px solid red;
}

body {
    background: #eff4fd;
}

* {
    margin: 0;
    padding: 0;
    /* background-color: #eff4fd; */
}

.custom-header-class {
    color: black !important;
    font-weight: bold !important;
    cursor: pointer !important;
}

.container {
    max-width: 100%;

}



figure {
    display: flex;
    
}

figure img {
    width: 150px;
    height: 100px;
    border-radius: 15%;
    border: 1.5px solid #f05a00;
    margin-right: 15px;
    padding: 0.3rem;
}

figure figcaption {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* 

@media (max-width: 1400px) {
    .container {
        transform: scale(0.75);
        transform-origin: top left;
    }
}

@media (max-width: 1300px) {
    .container {
        transform: scale(0.72);
        transform-origin: top left;
    }
}

@media (max-width: 1250px) {
    .container {
        transform: scale(0.70);
        transform-origin: top left;
    }
} */



@media (max-width: 1400px) {
    .container {
        zoom: 0.75;
        max-width: 100%;
        min-height: 100%;
    }

    .DialogContainer {
        zoom: 0.75;
    }

    .newContainermax {
        min-height: 500px;
    }

    .newMax_css {
        zoom: 1.35;
    }

    .new_style_text {
        zoom: 0.85;
    }

    .cchart {
        zoom: 1.35;
        max-height: 300px;
        /* width: 100%; */
    }
}

@media (max-width: 1300px) {
    .container {
        zoom: 0.73;
        max-width: 100%;
        min-height: 100%;
    }

    .newContainermax {
        zoom: 0.75;
        min-height: 700px;
    }

    .DialogContainer {
        zoom: 0.72;
    }

    .newMax_css {
        zoom: 1.39;
    }

    .new_style_text {
        zoom: 0.85;
    }
}

@media (max-width: 1250px) {
    .container {
        zoom: 0.70;
        max-width: 100%;
        min-height: 100%;
    }

    .newContainermax {
        zoom: 0.99;
    }

    .DialogContainer {
        zoom: 0.70;
    }

    .cchart {
        zoom: 1.45;
        max-height: 300px;
        /* width: 100%; */
    }

    .newMax_css {
        zoom: 1.45;
    }

    .new_style_text {
        zoom: 0.85;
    }

}

@media (max-width: 1100px) {
    .container {
        max-width: 100%;
        min-height: 100%;
    }

    .DialogContainer {
        zoom: 0.70;
    }

    .container {
        zoom: 0.70;
    }

    .newContainermax {
        zoom: 0.99;
    }

    .myChartOne {
        zoom: 1.4;
    }

    .newMax_css {
        zoom: 1.45;
    }

    .new_style_text {
        zoom: 0.75;
    }
}